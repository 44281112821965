<template>
  <div
    class="df m-r-16 m-l-16 p-l-20 p-r-20"
    style="flex-direction: column; height: 100%"
  >
    <div
      class="df ai-fs jc-fs"
      style="
        height: 75vh;
        padding-bottom: 10vh;
        padding-top: 10%;
        flex-direction: column;
      "
    >
      <!-- switcher -->
      <div class="modal-switcher">
        <span class="switcher-item" :class="{ active: checked }" @click="enter">
          Вход
        </span>

        <span
          class="switcher-item"
          :class="{ active: !checked }"
          @click="registration"
        >
          Регистрация
        </span>
      </div>

      <component
        :is="contentComponent"
        @close="$emit('close')"
        @code-check="$emit('show-CheckDialog', $event)"
        @show-AgreeDialog="$emit('showAgreeDialog')"
        @forgotten-password="$emit('forgotten-password')"
      />
    </div>

    <div
      class="df ai-fe jc-fe p-b-20"
      style="height: 25vh; flex-direction: column"
    >
      <div class="m-b-16" style="width: 18%; max-width: 100px">
        <a
          href="https://navigator.sk.ru/orn/1124520"
          target="_blank"
          class="ts-none__card"
        >
          <img
            :src="require('@/assets/img/logo_skolkovo.svg')"
            alt="logo_skolkovo"
            class="ts-none__img"
          />
        </a>
      </div>
      <el-button
        class="text-btn m-b-12"
        type="text"
        @click="$emit('showAgreeDialog')"
      >
        <div class="desktop-login_footer-link">Пользовательское соглашение</div>
      </el-button>

      <el-button
        class="text-btn m-b-12 m-l-0"
        type="text"
        @click="$emit('showFaqDialog')"
      >
        <div class="desktop-login_footer-link">Использование программы</div>
      </el-button>
    </div>
  </div>
</template>

<script>
import EntranceForm from '@/views/login/components/EntranceForm'
import RegistrationForm from '@/views/login/components/RegistrationForm'
export default {
  name: 'Login',
  components: {
    EntranceForm,
    RegistrationForm,
  },
  data() {
    return {
      checked: true,
      contentComponent: 'EntranceForm',
    }
  },
  methods: {
    enter() {
      this.contentComponent = 'EntranceForm'
      this.checked = true
    },
    registration() {
      this.contentComponent = 'RegistrationForm'
      this.checked = false
    },
  },
}
</script>

<style lang="sass">
.modal-switcher
  display: grid
  grid-template: 1fr / 1fr 1fr
  margin-bottom: 30px
  .switcher-item
    text-align: center
    background-color: transparent !important
    padding: 10px 40px
    border-bottom: 2px solid #e0e5e6
    cursor: pointer
    &.active
      border-bottom: 2px solid $color-blue-primary

.desktop-login_footer-link
  font-size: 14px !important
  border-bottom: 1px solid $color-blue
</style>
