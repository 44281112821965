var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "df m-r-16 m-l-16 p-l-20 p-r-20",
      staticStyle: { "flex-direction": "column", height: "100%" },
    },
    [
      _c(
        "div",
        {
          staticClass: "df ai-fs jc-fs",
          staticStyle: {
            height: "75vh",
            "padding-bottom": "10vh",
            "padding-top": "10%",
            "flex-direction": "column",
          },
        },
        [
          _c("div", { staticClass: "modal-switcher" }, [
            _c(
              "span",
              {
                staticClass: "switcher-item",
                class: { active: _vm.checked },
                on: { click: _vm.enter },
              },
              [_vm._v(" Вход ")]
            ),
            _c(
              "span",
              {
                staticClass: "switcher-item",
                class: { active: !_vm.checked },
                on: { click: _vm.registration },
              },
              [_vm._v(" Регистрация ")]
            ),
          ]),
          _c(_vm.contentComponent, {
            tag: "component",
            on: {
              close: function ($event) {
                return _vm.$emit("close")
              },
              "code-check": function ($event) {
                return _vm.$emit("show-CheckDialog", $event)
              },
              "show-AgreeDialog": function ($event) {
                return _vm.$emit("showAgreeDialog")
              },
              "forgotten-password": function ($event) {
                return _vm.$emit("forgotten-password")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "df ai-fe jc-fe p-b-20",
          staticStyle: { height: "25vh", "flex-direction": "column" },
        },
        [
          _c(
            "div",
            {
              staticClass: "m-b-16",
              staticStyle: { width: "18%", "max-width": "100px" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "ts-none__card",
                  attrs: {
                    href: "https://navigator.sk.ru/orn/1124520",
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    staticClass: "ts-none__img",
                    attrs: {
                      src: require("@/assets/img/logo_skolkovo.svg"),
                      alt: "logo_skolkovo",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "text-btn m-b-12",
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  return _vm.$emit("showAgreeDialog")
                },
              },
            },
            [
              _c("div", { staticClass: "desktop-login_footer-link" }, [
                _vm._v("Пользовательское соглашение"),
              ]),
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "text-btn m-b-12 m-l-0",
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  return _vm.$emit("showFaqDialog")
                },
              },
            },
            [
              _c("div", { staticClass: "desktop-login_footer-link" }, [
                _vm._v("Использование программы"),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }